import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { environment } from 'src/environments/environment';
import { LocalService } from './shared/services/local.service';
import { LoadingService } from './shared/services/loading.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [];
  listStatus = 'loaded';
  public isLoggedIn = false;
  public userProfile: KeycloakProfile | null = null;
  isBrowserEnabled: boolean = false;
  isResolutionEnabled: boolean = false;
  actualWidth = 1024;
  actualHeight = 500;
  email = 'john@doe.com'
  completeName = 'John Doe'
  orgs = []
  selectedOrg = undefined
  isMobile: boolean = false;

  constructor(
    private router: Router,
    private readonly keycloakService: KeycloakService,
    private readonly localService: LocalService,
    public loadingService: LoadingService,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.validateBrowser()
    this.validateResolution();

  }

  public async ngOnInit() {
    this.breakpointObserver.observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
        if (this.isMobile) {
        } else {
        }
      });
    this.isLoggedIn = await this.keycloakService.isLoggedIn();
    if (this.isLoggedIn) {
      await this.keycloakService.loadUserProfile().then(profile => {
        this.userProfile = profile;
        this.email = profile.email;
        const firstName = (profile.firstName ? profile.firstName : "");
        const lastName = (profile.lastName ? profile.lastName : "");
        this.completeName = `${firstName} ${lastName}`;
        if (profile['attributes'] && profile['attributes'].orgs && profile['attributes'].orgs.length > 0) {
          this.orgs = profile['attributes'].orgs[0].split(',')
        }
        this.selectedOrg = this.localService.getData(profile.id)
        if (!this.selectedOrg && this.orgs.length > 0) {
          this.onSelectOrg(this.orgs[0])
        }
      });
      console.log(`Org on init: ${this.selectedOrg}`)
    }
  }

  onSelectOrg(value) {
    if (value) {
      this.localService.saveData(this.userProfile.id, value)
      this.selectedOrg = value
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      }
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate(['/home']);
    }
    console.log(`Org on select: ${this.selectedOrg}`)
  }

  goToHome() {
    this.router.navigate(['/home']);
  }

  public logoff() {
    this.keycloakService.logout();
  }

  public profile() {
    window.open(`${environment.keycloakUrl}realms/${environment.keycloakRealm}/account/#/personal-info`, '_blank');
  }

  public validateBrowser() {
    const isChrome = /Chrome/.test(navigator.userAgent);
    const isFirefox = /Firefox/.test(navigator.userAgent);
    const isSafari = /Safari/.test(navigator.userAgent);
    this.isBrowserEnabled = (isChrome || isFirefox || isSafari);
  }

  @HostListener('window:resize', [])
  public validateResolution() {
    // const validWidth = window.innerWidth > this.actualWidth;
    // const validHeight = window.innerHeight > this.actualHeight;
    // this.isResolutionEnabled = (validWidth && validHeight);
    this.isResolutionEnabled = true;
  }

  public getMinimunResolution() {
    return `${this.actualWidth}x${this.actualHeight}`;
  }

  public getActualResolution() {
    return `${window.innerWidth}x${window.innerHeight}`;
  }
}
// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    .mat-dialog-actions {
      display: flex;
      justify-content: space-between;
    }
    
    .cancel-button {
      margin-right: auto;
    }
    
    .confirm-button {
      margin-left: auto;
    }
  `, "",{"version":3,"sources":["webpack://./src/app/modules/home/home.page.ts"],"names":[],"mappings":";IACI;MACE,aAAa;MACb,8BAA8B;IAChC;;IAEA;MACE,kBAAkB;IACpB;;IAEA;MACE,iBAAiB;IACnB","sourcesContent":["\n    .mat-dialog-actions {\n      display: flex;\n      justify-content: space-between;\n    }\n    \n    .cancel-button {\n      margin-right: auto;\n    }\n    \n    .confirm-button {\n      margin-left: auto;\n    }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-footer-right {
  float: right;
}

.button-footer-left {
  float: left;
}

.test-execution-form {
  width: 100%;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 40%;
  margin-right: 5px;
  margin-left: 5px;
}

.third-width {
  width: 30%;
  margin-right: 5px;
  margin-left: 5px;
}

div.scroll {
  height: 370px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-left: 30px;
  float: left;
  margin-bottom: 25px;
}

#additionalParameters::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
  border-radius: 10px;
}

#additionalParameters::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

#additionalParameters::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.44, rgb(122, 153, 217)), color-stop(0.72, rgb(73, 125, 189)), color-stop(0.86, rgb(28, 58, 148)));
}

th.mat-sort-header-sorted {
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/home/home.page.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EAGI,WAAA;AADJ;;AAIA;EACI,WAAA;AADJ;;AAIA;EACI,UAAA;EACA,iBAAA;EACA,gBAAA;AADJ;;AAIA;EACI,UAAA;EACA,iBAAA;EACA,gBAAA;AADJ;;AAIA;EACI,aAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;EACA,mBAAA;AADJ;;AAIA;EACI,oDAAA;EACA,yBAAA;EACA,mBAAA;AADJ;;AAIA;EACI,WAAA;EACA,yBAAA;AADJ;;AAIA;EACI,mBAAA;EACA,gLAAA;AADJ;;AAaA;EACI,YAAA;AAVJ","sourcesContent":[".button-footer-right {\n    float: right;\n}\n\n.button-footer-left {\n    float: left;\n}\n\n.test-execution-form {\n    // min-width: 150px;\n    // max-width: 500px;\n    width: 100%;\n}\n\n.full-width {\n    width: 100%;\n}\n\n.half-width {\n    width: 40%;\n    margin-right: 5px;\n    margin-left: 5px;\n}\n\n.third-width {\n    width: 30%;\n    margin-right: 5px;\n    margin-left: 5px;\n}\n\ndiv.scroll {\n    height: 370px;\n    overflow-x: hidden;\n    overflow-y: auto;\n    margin-left: 30px;\n    float: left;\n    margin-bottom: 25px;\n}\n\n#additionalParameters::-webkit-scrollbar-track {\n    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);\n    background-color: #F5F5F5;\n    border-radius: 10px;\n}\n\n#additionalParameters::-webkit-scrollbar {\n    width: 10px;\n    background-color: #F5F5F5;\n}\n\n#additionalParameters::-webkit-scrollbar-thumb {\n    border-radius: 10px;\n    background-image: -webkit-gradient(linear,\n            left bottom,\n            left top,\n            color-stop(0.44, rgb(122, 153, 217)),\n            color-stop(0.72, rgb(73, 125, 189)),\n            color-stop(0.86, rgb(28, 58, 148)));\n}\n\n// mat-radio-button > .mat-mdc-radio-button > .mdc-radio {\n//     visibility: hidden !important;\n// }\n\nth.mat-sort-header-sorted {\n    color: black;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
